import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import TwHero from '../../components/TwHero';

const Testimonials = (props) => {
  const testimonials = props.data.allMdx.edges;
  const heroBackground = props.data.heroBackground;
  return (
    <Layout bodyClass="page-testimonials">
      <SEO title="Testimonials" />
      <TwHero
        bg={heroBackground}
        headline="Minnesota Residential Furnace / Air Conditioning (AC) Service, Installation, and Repair"
        text="Family-owned HVAC business operated out of our Burnsville, MN headquarters. We have a small team that will do whatever it takes to earn your business. AC or Furnace troubles? We can help!"
        btnURL="/hvac-service-installation-repair-scheduling"
        btnTxt="Schedule Home HVAC Service"
        featured={[
          {
            name: 'HVAC Housecall',
            color: 'red-800',
            href: '/hvac-service-installation-repair-scheduling',
            description: `HVAC units don't sleep! We offer emergency 24/7 emergency repair, inspection, and installation. If your AC or Furnace is doing anything out of the ordinary, let us know!`,
            icon: '/2022/icons/minnesota-hvac-service-repair-installation.svg',
            btnTxt: 'Get HVAC Support',
          },
          {
            name: 'Furnace Installation & Repairs',
            href: '/hvac-service-installation-repair-scheduling',
            color: 'orange-500',
            description: `Need a new furnace installation? We've got you covered. As a trusted Bryant® HVAC installation team, we've got you covered with the best HVAC units we've found to date. `,
            icon: '/2022/icons/minnesota-furnace-hvac-service-installation-repair.svg',
            btnTxt: 'Schedule Furnace or Heater Service',
          },
          {
            name: 'AC Installation, Service, Repair',
            href: '/hvac-service-installation-repair-scheduling',
            color: 'blue-500',
            description: `Our 3-month window of NEEDING a functioning air conditioning unit is upon us. Coincidentally this is also when they typically decide to die. If you either don't have one or have one on the fritz, get in touch. We can help with AC installations, inspections, and repairs.`,
            icon: '/2022/icons/best-ac-air-conditioning-service-repair-minnesota.svg',
            btnTxt: 'Schedule AC Service or Installation',
          },
        ]}
      />

      <div>
        <div className="max-w-screen-xl mx-auto p-4">
          {testimonials.map((edge, i) => (
            <div key={`${i}-${edge.node.frontmatter.path}`} className="mb-20">
              <div className="">
                <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                  {edge.node.frontmatter.title}
                </h2>
                <p className="mt-4 text-base text-gray-500">
                  {edge.node.frontmatter.name}
                </p>
              </div>
              <MDXRenderer>{edge.node.body}</MDXRenderer>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query TestimonialsQuery {
    allMdx(
      filter: { fileAbsolutePath: { regex: "../../mdx/testimonials/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          body
          frontmatter {
            title
            path
            name
          }
        }
      }
    }
    heroBackground: file(
      relativePath: {
        eq: "2022/best-twin-cities-hvac-company-ac-furnace-service-repair.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default Testimonials;
